@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;700&display=swap");

html {
  height: 100%;
  width: 100%;
}
body {
  color: white;
  font-family: "Space Grotesk", "Roboto", sans-serif;
  background: #845ff8;
  background-image: url("./img/bg.svg");
  background-size: fit;
  background-repeat: no-repeat;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

h1,
h2,
h3 {
  font-family: "Space Grotesk", "Roboto", sans-serif;
  font-weight: bold;
}
h2 {
  font-size: 2.5rem;
}

p {
}

li {
  font-size: 1.2rem;
  line-height: 1.5;
}
@media (min-width: 1024px) {
  p {
    font-size: 1.5rem;
  }
  li {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}

img {
  width: 100%;
}

section {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  /* min-height: 90vh; */
  /* height: 100vh; */
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  text-align: left;
  opacity: 0;
  transition: all 500ms;
  pointer-events: none;
}

section.show {
  opacity: 1;
  pointer-events: auto;
}

@media (min-width: 1024px) {
  section {
    height: 100vh;
    overflow: hidden;
    flex-direction: row;
  }
}

/* SEC A */
.secA {
  aspect-ratio: 1;
}

.secA img,
.secA video {
  width: 100%;
  height: auto;
}
@media (max-width: 1024px) {
  /* .secA {
    width: auto;
    height: 100%;
  }
  .secA img {
    width: auto;
    height: 100%;
  } */
}

/* SEC B */
.secB {
  /* flex-shrink: 1; */
  padding: 2rem;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: start;
}

@media (min-width: 1024px) {
  .secB {
    padding: 4rem;
    justify-content: center;
    /* justify-content: space-evenly; */
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* other ------------------ */

.nav-btns {
  /* position: fixed;
  bottom: 2rem; */
}

.desktopOnly {
  display: none;
}
.mobileOnly {
}

@media (min-width: 1024px) {
  .desktopOnly {
    display: block;
  }
  .mobileOnly {
    display: none;
  }
}

.nav-btns {
  margin-top: 30px;
  text-align: center;
  /* position: fixed; */
  z-index: 5;
  bottom: 30px;
  left: 0;
  right: 0;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s;
  direction: ltr;
}
.nav-btns button {
  color: white;
  background: rgba(72, 53, 255, 1);
  border: 0;
  box-shadow: 0 2px 6px -2px rgb(0 0 0 / 50%);
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: all 0.1s;
  outline: 0;

  font-family: "Space Grotesk", "Helvetica Neue", Helvetica, Roboto, sans-serif;
  font-size: 16.2px;
  line-height: 32px;
  font-weight: 400 !important;

  height: 40px;
  text-transform: none;
  font-size: 16px;
  vertical-align: top;
  padding: 8px 20px;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
}

.nav-btns .btn-prev {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.nav-btns .btn-next {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.sec-survey {
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.survey-container {
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}
.survey-inner {
  padding: 2rem 0;
}

@media (min-width: 1024px) {
  .sec-survey {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .survey-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-between;
  }

  .survey-inner {
    padding: 2rem;
  }
}

.btn {
  padding: 0.2rem 0.5rem;
  border-radius: 100%;
  border: 0;
  outline: 0;
  color: white;
  background: black;
  font-size: 16px;
  font-family: "Space Grotesk", "Roboto", sans-serif;
  cursor: pointer;
}

.btn-credit {
  position: fixed;
  right: 10px;
  bottom: 10px;
  border-radius: 5px;
  background: transparent;
  opacity: 0.5;
  font-size: 10px;
}

@media (min-width: 1024px) {
  .btn-credit {
    font-size: 14px;
  }
}

.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}
.modal {
  background: rgba(0, 0, 0, 0.8);
  position: relative;
  width: 600px;
  max-width: 80vw;
  height: 80vh;
  overflow-y: auto;
  padding: 5vw;
  border-radius: 30px;
  backdrop-filter: blur(10px);
}

@media (min-width: 1024px) {
  .modal {
    padding: 4vw 2vw;
    height: auto;
  }
}
.modal p {
  font-size: 16px;
  line-height: 1.5;
}

.note {
  color: rgb(200, 200, 200);
  font-size: 12px;
}

/* Page ------------------ */

/* intro */
/* mobile */
@media (max-width: 1024px) {
  /* .intro .secB {
    justify-content: flex-start;
    margin-top: -17vw;
    background-position-y: -153vw;
    background-image: url("./img/bg.svg");
    position: relative;
    z-index: 9;
  } */
}

/* helper */
.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
